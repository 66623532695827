import {BottomPadding, TopPadding} from "../../ui/multiUseStyle";
import React from "react";
import Container from "react-bootstrap/cjs/Container";
import Row from "react-bootstrap/cjs/Row";
import styled from "@emotion/styled";
import Col from "react-bootstrap/cjs/Col";

function Rally() {
    return (
        <>
            <TopPadding/>
            <Container>
                <br/>
                <Row>
                    <CenterText>
                        <h1>Sint Michiels Rally</h1>
                        <p>Mis dit event niet en nodig al je vrienden uit om deel te nemen of te komen supporteren.</p>
                        <a target={"_blank"} rel={"noreferrer"}
                           href={"https://forms.gle/EUpiJyaQkpBKdfJS6"}>
                            <SignUpButton>Schrijf je in!</SignUpButton>
                        </a>
                    </CenterText>
                </Row>
                <hr/>
                <Row>
                    <Col>
                        <h3 style={{textDecoration: "underline"}}>Reglement:</h3>
                        <br/>
                        <ol>
                            <li>
                                Algemene bepalingen
                                <ul>
                                    <li>De SINT MICHIELS RALLY wordt georganiseerd door de Studentenclub MERETA</li>
                                    <li>Het evenement vindt plaats op 30 april in het Sint-Michielspark te
                                        Sint-Katelijne-Waver.
                                    </li>
                                    <li>Deelnemers dienen zelf te zorgen voor een goede conditie en gezondheid.</li>
                                </ul>
                            </li>

                            <li>
                                Doel van de RALLY
                                <ul>
                                    <li>Het doel van deze rally is om in één uur tijd zoveel mogelijk rondjes te lopen
                                        op het aangewezen parcours.
                                    </li>
                                    <li>Bij elke passage van de startlijn is het verplicht om één consumptie te drinken
                                        en één bitterbal te eten. Het niet voltooien van de verplichte consumpties kan
                                        leiden tot diskwalificatie.
                                    </li>
                                </ul>
                            </li>

                            <li>
                                Parcours en Starttijd
                                <ul>
                                    <li>Het parcours wordt aangegeven op de dag van het evenement.
                                    </li>
                                    <li>De starttijd is 19u, dit wordt duidelijk gecommuniceerd voor aanvang van de
                                        rally.
                                    </li>
                                </ul>
                            </li>

                            <li>
                                Deelnamecategorieën
                                <ul>
                                    <li>Deelnemers kunnen individueel deelnemen en in ploegverband.</li>
                                    <li>Teams kunnen bestaan uit meerdere lopers die elkaar aflossen. Elke deelnemer
                                        moet echter de verplichte consumpties bij elke passage van de startlijn
                                        voltooien. Het maximum aantal deelnemers per ploeg is 3.
                                    </li>
                                    <li>Er zullen maximaal 30 deelnemers (individueel of ploegen) kunnen deelnemen aan
                                        de Sint Michiels Rally.
                                    </li>
                                </ul>
                            </li>

                            <li>
                                Punten en Prijzen
                                <ul>
                                    <li>Er zijn prijscategorieën voor individuele deelnemers en ploegen, waarbij degenen
                                        met het hoogste totale aantal afgelegde rondes als winnaars worden beschouwd.
                                    </li>
                                    <li>Bij een gelijk aantal rondes wordt gekeken naar de snelheid van de tweede ronde
                                        om de winnaar te bepalen.
                                    </li>
                                </ul>
                            </li>

                            <li>
                                Veiligheid en Gedrag
                                <ul>
                                    <li>Deelnemers dienen het parcours te respecteren en de aanwijzingen van de
                                        organisatoren op te volgen.
                                    </li>
                                    <li>Deelnemers dienen zich sportief en respectvol te gedragen jegens mededeelnemers,
                                        vrijwilligers, toeschouwers, de omgeving en buren. Wangedrag kan leiden tot
                                        diskwalificatie.
                                    </li>
                                    <li>Het gebruik van prestatiebevorderende middelen is ten strengste verboden.</li>
                                </ul>
                            </li>

                            <li>
                                Uitrusting
                                <ul>
                                    <li>Deelnemers dienen geschikte sportkleding en -schoenen te dragen.</li>
                                    <li>Elke deelnemer is verplicht een borstnummer te dragen.</li>
                                </ul>
                            </li>

                            <li>
                                Inschrijving en Registratie
                                <ul>
                                    <li>Inschrijving gebeurt vooraf via e-mail of ter plaatse op de dag van het
                                        evenement zolang er plaats is.
                                    </li>
                                </ul>
                            </li>

                            <li>
                                Afsluiting en Prijsuitreiking
                                <ul>
                                    <li>De rally eindigt op het aangegeven tijdstip. Na de wedstrijd vindt een
                                        volksfeest plaats met gratis bitterballen voor iedereen. De prijsuitreiking
                                        vindt plaats na de rally.
                                    </li>
                                </ul>
                            </li>

                            <li>
                                Verantwoordelijkheid
                                <ul>
                                    <li>In gevallen waarin dit reglement niet voorziet, beslist de organisatie.</li>
                                    <li>Deelnemers nemen op eigen risico deel aan de MERETA RALLY; Studentenclub MERETA
                                        is niet aansprakelijk voor letsel, schade of verlies van eigendommen.
                                    </li>
                                    <li>Deelnemers dienen zelf te zorgen voor een goede conditie en gezondheid.</li>
                                </ul>
                            </li>
                        </ol>
<br/>
                        <p>
                            Door deel te nemen aan de SINT MICHIELS RALLY, gaan deelnemers akkoord met dit reglement en
                            verbinden zij zich ertoe de regels en voorschriften van het evenement te volgen. De
                            organisatie behoudt zich het recht voor om het reglement te wijzigen indien nodig voor een
                            soepel verloop van het evenement.
                        </p>
                    </Col>
                </Row>
            </Container>
            <BottomPadding/>
        </>
    )
}

const SignUpButton = styled.button`
    position: relative;
    margin: 10px 7px;
    padding: 10px 10px;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 2px;
    color: #034073;
    border: 2px #034073 solid;
    border-radius: 4px;
    text-transform: uppercase;
    overflow: hidden;
    background: none;
    z-index: 1;
    cursor: pointer;
    transition: 0.1s ease-in;
    -o-transition: 0.1s ease-in;
    -moz-transition: 0.1s ease-in;
    -webkit-transition: 0.1s ease-in;

    &:hover {
        color: whitesmoke;
        border: 0 #034073 solid;
    }

    &:before {
        content: "";
        position: absolute;
        background: #034073;
        bottom: 0;
        left: 0;
        right: 0;
        top: 100%;
        z-index: -1;
        -webkit-transition: top 0.1s ease-in;
    }

    &:hover:before {
        top: 0;
    }
`;

const CenterText = styled.div`
    width: 100%;
    text-align: center;
`;

export default Rally;
