import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import styled from "@emotion/styled";
import WAC from "../../images/WAC.png";

export function WACModal() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    useEffect(() => {
        const timer = setTimeout(() => {
          setShow(true);
        }, 1000);
        return () => clearTimeout(timer);
      }, []);

    return (
        <Modal show={show} onHide={handleClose} centered size="xl">
            <Modal.Header closeButton>
                <HeaderImg src={WAC} alt={"We Are Chemistry JOBEVENT"}/>
            </Modal.Header>
            <Modal.Body>
                <p>
                Benieuwd wat de chemie, kunststoffen en life sciences voor jou kunnen betekenen? Ben je op zoek naar een job of gewoon nieuwsgierig?
                </p>
                <p>
                Ontdek alle mogelijkheden tijdens de 13de editie van We Are Chemistry!
                </p>
                <p>
                Noteer alvast 26 maart, want dan stellen 22 toonaangevende bedrijven uit de sector zich voor aan de hand van heldere, frisse mindmaps. 
                Groot of klein bedrijf? Multinational of Belgisch met internationale uitstraling? 
                In de haven van Antwerpen, de Kempen of Mechelen? Je ontdekt het allemaal op We Are Chemistry, hét grootste jobevent voor de chemie, kunststoffen en life sciences cluster uit de provincie Antwerpen!<br/>
                </p>
                <p>
                Wat mag je verwachten?
                </p>
                <p>
                Een combinatie van:
                <DetailList>
                <li>➞ Korte maar krachtige speedpresentaties van de 22 bedrijven;</li>
                <li>➞ Joblounge en matchmaking voor krachtige een-op-eengesprekken;</li>
                <li>➞ Een netwerkdrink met de bedrijven! Het ideale moment dus om vragen te stellen aan mensen uit de sector of, wie weet, aan jouw toekomstige werkgever!</li>
                </DetailList>
                De <b>22 bedrijven</b> uit de sector zijn: Kuraray, VOPAK, Advario, EuroChem Antwerpen, Imerys, BASF, TotalEnergies, Indaver, ExxonMobil, Covestro, Fujifilm Electronic Materials, Borealis, Johnson&Johnson, Bayer, Evonik, Oleon, LanXess, Lonza, Sea-Tank, Ineos, Q8 en SCK CEN. Allemaal bedrijven uit de sector die continu op zoek zijn naar nieuw talent uit alle wind- en studierichtingen.
                </p>
                <p>
                Benieuwd naar meer of wil je jouw carrièrekansen ontdekken? Neem dan zeker deel aan We Are Chemistry. Schrijf je nu gratis in!
                </p>
                <p>

                Praktisch:
                </p>
                <p>
                <b>Gratis inschrijven</b> via de website van We Are Chemistry: <a href="https://www.wearechemistry.be" target="_blank">https://www.wearechemistry.be</a>. Hier vind je ook het programma, de mindmaps en vacatures van de deelnemende bedrijven.

              <DetailList>
                  <li>🗓 26 maart 2025</li>
                  <li>🕒 17:00 - 21:00</li>
                  <li>📍 Bluepoint Antwerpen</li>
              </DetailList>


                Heel graag tot dan!
                </p>
            </Modal.Body>
        </Modal>
    )
}

const HeaderImg = styled.img`
  width: 97%;
  border-radius: 5px;
`;

const DetailList = styled.ul`
  list-style: none;
  padding: 10px 8px;
  margin: 0;
`;

