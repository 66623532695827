import React from "react";
import styled from "@emotion/styled";
import MeretaClubhuis from "../../images/clubhuis.webp";
import OpenLuchtTD from "../../images/oltdStage.webp";
import Polaroids from "../../images/polaroidsLarge.webp";
import VideoBanner from "../../images/BannerVideo.mp4";
import VideoPoster from "../../images/VideoPoster.png";
import {BottomPadding, C, ImgResponsive, TopPadding} from "../../ui/multiUseStyle";
import Container from "react-bootstrap/cjs/Container";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import { WACModal } from "../modal/WACModal";

function Home() {
    return (
        <>
          <WACModal/>
          <Banner autoPlay muted loop poster={VideoPoster}>
              <source src={VideoBanner} type='video/mp4'/>
          </Banner>
          <TopPadding/>
          <Container>
              <br/>
              <Row>
                  <Col sm={8}>
                      <br/>
                      <h1><C>W</C>elkom op de webstek van Mereta</h1>
                      <p>
                          Mereta is een onafhankelijke studentenvereniging die alle studenten van Campus De Nayer te
                          Sint-Katelijne-Waver groepeert. Wij vertegenwoordigen deze studenten en verdedigen hun
                          belangen zowel binnen als buiten de hogeschool.
                      </p>
                      <p>
                          Mereta staat voor stijl, klasse en traditie. Via een gevarieerd activiteitenprogramma
                          richten wij ons vooral op ‘student zijn’, ’carrière maken’ en ‘plezier beleven’. De nadruk
                          ligt daarbij steeds op amusement en kameraadschap. De wetten van de Clubcodex liggen aan de
                          basis van onze werking, maar we vergeten echter in geen geval dat we een hedendaagse,
                          dynamische studentenvereniging zijn, gericht op de toekomst. Mereta groepeert een 100-tal
                          studenten en tientallen oud-studenten en heeft contacten in binnen- en buitenland en is
                          vertegenwoordigd in het Europees Studentenverbond (EKV), een NGO die zetelt in de Raad van
                          Europa.
                      </p>
                      <br/>
                  </Col>
                  <CenterContentCol sm={4}>
                      <a target={"_blank"} rel={"noreferrer"}
                          href={"https://forms.gle/AKRHCXNtXXkpLAyF7"}><JoinButton>Word
                          Mereta!</JoinButton></a>
                  </CenterContentCol>
              </Row>
              <br/>
          </Container>
          <Parallax>
              <ParallaxContainer>
                  <br/>
                  <Row>
                      <Col>
                          <h1><C>C</C>lubhuis</h1>
                          <br/>
                          <SubText>Met ons eigen clubhuis in Sint-Katelijne-Waver!</SubText>
                      </Col>
                  </Row>
                  <ParallaxImg src={Polaroids} alt={""}/>
                  <br/>
              </ParallaxContainer>
          </Parallax>
          <Container>
              <br/><br/>
              <Row>
                  <Col sm={4}>
                      <OltdImg src={OpenLuchtTD} alt={""}/>
                  </Col>
                  <Col sm={8}>
                      <h1><C>O</C>penlucht TD</h1>
                      <p>
                          Traditioneel sluit Mereta het academiejaar af met een geweldige OPENLUCHT TD. Fantastische
                          Dj’s, de beste muziek, flitsende spots, honderden liters drank en de schitterende locatie
                          rond Kasteelruïne Ter Elst zorgden jaarlijks voor een onvergetelijk einde van het
                          academiejaar. Met gemiddeld 1500 bezoekers is dit evenement uitgegroeid tot een van de
                          grootste studentenfuiven uit de regio. Door veiligheidsredenen is de OPENLUCHT TD moeten
                          verhuizen, eerst naar het domein De Locht en dan later naar de spoorweglaan tegenover de
                          brandweerkazerne van Duffel. Tijdens de laatste editie, bij het 20 jarige bestaan trokken we
                          maar liefst 2500 bezoekers!
                      </p>
                  </Col>
              </Row>
              <br/>
          </Container>
          <BottomPadding/>
        </>
    )
}

const Banner = styled.video`
  padding-top: 56px;
  position: relative;
  width: 100%;
  overflow-y: hidden;
`;

const Parallax = styled.div`
  background-image: url(${MeretaClubhuis});
  position: relative;
  background-attachment: fixed !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #4e555b;
  z-index: -10;
`;

const ParallaxContainer = styled(Container)`
  color: white;
  padding-top: 5vh;
`;

const SubText = styled.h3`
  font-style: italic;
`;

const ParallaxImg = styled(ImgResponsive)`
  top: -140px;
  position: relative;
  @media (max-width: 1250px) {
    top: 0;
    padding-bottom: 3vh;
  }
`;

const CenterContentCol = styled(Col)`
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OltdImg = styled(ImgResponsive)`
  border-radius: 8px;
`;

const JoinButton = styled.button`
  position: relative;
  margin: 10px 7px;
  padding: 10px 10px;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 2px;
  color: #034073;
  border: 2px #034073 solid;
  border-radius: 4px;
  text-transform: uppercase;
  overflow: hidden;
  background: none;
  z-index: 1;
  cursor: pointer;
  transition: 0.1s ease-in;
  -o-transition: 0.1s ease-in;
  -moz-transition: 0.1s ease-in;
  -webkit-transition: 0.1s ease-in;

  &:hover {
    color: whitesmoke;
    border: 2px #034073 solid;
  }

  &:before {
    content: "";
    position: absolute;
    background: #034073;
    bottom: 0;
    left: 0;
    right: 0;
    top: 100%;
    z-index: -1;
    -webkit-transition: top 0.1s ease-in;
  }

  &:hover:before {
    top: 0;
  }
`;

export default Home;
